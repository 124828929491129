const Links = () => {
    return <>
    <figure class="wp-block-table">
    <table class="adhd-yoga-links">
        <tbody>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="https://trulyadhd.ca/" data-type="URL" data-id="https://trulyadhd.ca/" rel="noreferrer">Truly ADHD Homepage</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="https://adhdyoga.ca/join-my-email-list/" data-type="URL" data-id="https://adhdyoga.ca/join-my-email-list/" rel="noreferrer">Join My Newsletter</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="https://dogoodpaper.co/collections/planners/products/mindful-productivity-planner-sage" rel="noreferrer"><b>15%</b> Off the Mindful Productivity Planner Promo Code: ADHD15</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="https://podcasts.google.com/feed/aHR0cHM6Ly9paGF2ZWFkaGQubGlic3luLmNvbS9yc3M/episode/N2E2NzJjNTQtYmUzNi00YzQ3LTg5OWMtN2M3MDc1ODlmMjE4?hl=en-CA&amp;ved=2ahUKEwihzpmCionxAhUGEqwKHQrEA9oQjrkEegQICRAF&amp;ep=6&amp;fbclid=IwAR0EFbHqTQqwRVDLAgPOSHqroupqTNGjFy3mGoJqeyqHP7jt" rel="noreferrer">I Have ADHD Podcast: The Benefits of Yoga and Other Musings</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="https://www.patriciasung.com/podcast/episode-092" rel="noreferrer">Motherhood in ADHD: Episode #92 Diagnosis Story</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="http://www.patriciasung.com/podcast/episode-093" rel="noreferrer">Motherhood in ADHD:
                    Episode #93 Mindfulness Techniques</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="https://www.patriciasung.com/podcast/episode-094" rel="noreferrer">Motherhood in ADHD:
                    Episode #94 Mindfulness &amp; How to Get Started</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="https://www.womenandadhd.com/episodes/episode/4618e6f3/taucha-post-yoga-and-post-itr-notes" rel="noreferrer">Women &amp; ADHD: Podcast Interview</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a target="_blank" href="https://pod.link/adultingwithadhd" rel="noreferrer">Adulting with ADHD Podcast: ADHD &amp;
                    Trying to Conceive</a></td>
            </tr>
            <tr>
                <td class="has-text-align-center" data-align="center"><a href="https://podcasts.apple.com/ca/podcast/bend-the-podcast/id1534156295#episodeGuid=d202be53-9a79-4e94-ab2b-d46f380c5aff">Bend
                    Wellness: Podcast Interview</a></td>
            </tr>
        </tbody>
    </table>
</figure>
</>;
};

export default Links;