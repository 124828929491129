import certification from '../images/Focused-ADHD-Coach_1500.png';
import { PopupWidget } from "react-calendly";

const Home = () => {
    return (<>
      <div className='container-sm'>
        <h1 className='tagline'>My job is to help you challenge your thoughts and process your emotions so you can do the shit you <b>truly</b> want to do!</h1>
        <br/>
        <h2>$110 CAD / 1-hour session<br/><span className='session-package'>or $100 / session for 12 sessions</span></h2>
        <br/>
        <div className='container book-now'>
          <PopupWidget
            url="https://calendly.com/truly-adhd/consultation"
            rootElement={document.getElementById("root")}
            text="Book a FREE ADHD Coaching Consultation!"
            textColor="#ffffff"
            color="#AC86DD"
          />
        </div>
      </div>
      <br/>
      <div>
        <img className='certification' src={certification} alt="Certification" />
      </div>
    </>);
  };
  
export default Home;