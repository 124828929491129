import { useState } from 'react';
import { Outlet, NavLink } from "react-router-dom";
import logo from '../images/logo512.png';
import instagramLogo from '../images/instagram.png';

const Layout = () => {
    const [isShown, setIsShown] = useState(false);
    const toggleMobileMenu = () => { setIsShown(!isShown); };

    const Menu = () => {
        return (
            <>
                <NavLink to="https://trulyadhd.ca/">Home</NavLink>
                {/* <NavLink to="/blogs">My Story</NavLink>
                <NavLink to="/contact">Qualifications</NavLink> */}
                <NavLink to="https://www.instagram.com/adhd.yoga/"><img id='instagram-logo' src={instagramLogo} alt="Instagram Logo" />&nbsp;@adhd.yoga</NavLink>
            </>
        )
    }

    return (
        <>
            <div className='topnav'>
                {/* Logo/Brand */}
                <div className='logo'>
                    <img id='logo' src={logo} alt="Logo" />
                    <span>Truly ADHD</span>
                </div>

                {/* Desktop Menu, which only appears on large screens */}
                <div className={'menu'}>
                    <Menu />
                </div>

                {/* This button only shows up on small screens. It is used to open the mobile menu */}
                <button className='show-mobile-menu-button' onClick={toggleMobileMenu}>
                    &#8801;
                </button>
            </div>

            {/* The mobile menu and the close button */}
            {isShown && 
                <div className={'mobile-menu'}>
                    <Menu />
                </div>
            }

            {isShown && (
                <button className='close-mobile-menu-button' onClick={toggleMobileMenu}>
                    &times;
                </button>
            )}

            <br/>
            <Outlet />
        </>
    )
};

export default Layout;